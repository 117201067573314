import './App.css'

import React, {
  createContext,
  useReducer,
  useEffect,
} from 'react'

import {
  Routes,
  Route,
  useNavigate
} from 'react-router-dom'

import { extendTheme, CssVarsProvider } from '@mui/joy/styles'
import CssBaseline from '@mui/joy/CssBaseline'

import {
  getJWT,
  verifyJWT,
  setJWT,
  removeJWT,
  decodeJWT
} from './util/jwt'

import Login from './components/login'
import Aggregate from './components/aggregate'
import Home from './components/home'
import Accounts from './components/accounts'
import AccountDetails from './components/account_details'
import Cashflow from './components/cashflow'
import MessageModal from './components/modal/message'
import api from './util/api'

export const AppContext = createContext()

const theme = extendTheme({
  fontFamily: {
    body: 'Sofia Sans',
    heading: 'Sofia Sans'
  }
})

const initialState = {
  webJWT: null,
  decodedJWT: null,
  personId: null,
  accounts: null,
  aggData: null,
  bankData: null,
  msgModal: null,
  modal: null
}

const reducer = (state = initialState, action) => {
  const newState = { ...state }
  switch (action.type) {
    case 'set':
      newState[action.state] = action.value
      return newState
    case 'startover': {
      newState.personId = null
      newState.accounts = null
      newState.aggData = null
      return newState
    }
    case 'reset':
      return initialState
    default:
      return newState
  }
}

const App = () => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const navigate = useNavigate()

  const getToken = async () => {
    const token = getJWT()
    if (!token) return

    const verify = await verifyJWT(token)
    if (!verify) return
    return token
  }

  const handleLogout = () => {
    dispatch({ type: 'reset' })
    removeJWT()
    navigate('/')
  }

  const fetchToken = async (loginJWT) => {
    const data = { login_token: loginJWT }
    try {
      const resp = await api.token(data)
      dispatch({
        type: 'set',
        state: 'webJWT',
        value: resp.data
      })
      setJWT(resp.data)
    }
    catch (e) {
      console.log('Error signing up', e)
    }
  }

  // const registerConsumer = useCallback(async () => {
  //   const data = {
  //     first_name: 'Jane',
  //     last_name: 'Doe',
  //     email: 'janedoe@example.com'
  //   }
  //   try {
  //     const resp = await api.register(state.webJWT, data)
  //     dispatch({
  //       type: 'set',
  //       state: 'personId',
  //       value: resp.data.person_id
  //     })
  //   }
  //   catch (e) {
  //     console.log('Error registering consumer', e)
  //   }
  // }, [state.webJWT, dispatch])

  // useEffect(() => {
  //   if (state.webJWT) {
  //     registerConsumer()
  //   }
  // }, [state.webJWT, registerConsumer])

  useEffect(() => {
    const init = async () => {
      const token = await getToken()
      if (!token) {
        return
      }
      dispatch({
        type: 'set',
        state: 'webJWT',
        value: token
      })
      dispatch({
        type: 'set',
        state: 'decodedJWT',
        value: decodeJWT(token)
      })
    }
    init()
  }, [])

  const showAlert = (message, disableOk=false) => {
    if (!message) {
      dispatch({
        type: 'set',
        state: 'msgModal',
        value: null
      })
    }
    dispatch({
      type: 'set',
      state: 'msgModal',
      value: (
        <MessageModal content={message} disableOk={disableOk} />
      )
    })
  }

  const renderAlerts = () => {
    if (state.msgModal) {
      return state.msgModal
    }
    return
  }

  const value = {
    state,
    dispatch,
    showAlert,
    handleLogout,
    fetchToken,
  }

  const renderRoutes = () => {
    return (
      <Routes>
        {state.webJWT ?
          <>
            <Route path='/' element={<Home />} />
            <Route path='/aggregate' element={<Aggregate />} />
            <Route path='/account-details' element={<AccountDetails />} />
            <Route path='/accounts' element={<Accounts />} />
            <Route path='/cashflow' element={<Cashflow />} />
            <Route path='*' element={<Home />} />
          </> :
          <>
            <Route path='/login' element={<Login />} />
            <Route path='*' element={<Login />} />
          </>
        }
      </Routes>
    )
  }

  return (
    <CssVarsProvider theme={theme}>
      <AppContext.Provider value={value}>
        <CssBaseline />
        {renderRoutes()}
        {renderAlerts()}
      </AppContext.Provider>
    </CssVarsProvider>
  )
}

export default App
