import React, { useState, useContext } from 'react'
import { useNavigate } from 'react-router-dom'

import Box from '@mui/joy/Box'
import Typography from '@mui/joy/Typography'
import Button from '@mui/joy/Button'
import Stack from '@mui/joy/Stack'
import Select from '@mui/joy/Select'
import Option from '@mui/joy/Option'
import OpenInNew from '@mui/icons-material/OpenInNew'

import PageWrapper from './page_wrapper'
import JsonModal from './modal/json'
import { AppContext } from '../App'
import api from '../util/api'

export default function Cashflow() {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [modal, setModal] = useState(null)
  const [accountId, setAccountId] = useState(null)
  const { state, dispatch, showAlert } = useContext(AppContext)

  const getTransactions = async () => {
    if (!state.aggData?.aggId) {
      showAlert('Please complete previous steps first.')
      return
    }
    const params = {
      accountId,
      startTime: '2023-01-01T00:00:00Z',
      endTime: '2024-05-15T00:00:00Z',
      offset: 0,
      limit: 100
    }
    setLoading(true)
    const res = await api.transactions(state.webJWT, state.aggData.aggId, params)
    setLoading(false)
    if (!res || res.status !== 200) {
      showAlert('There was a problem with your request.')
      return
    }
    setModal(
      <JsonModal
        data={res.data}
        title='Transactions'
        handleClose={() => setModal(null)}
      />
    )
  }

  const renderForm = () => {
    if (!state.personId) {
      return (
        <>
          <div>
            <Typography level="h4" component="h1">
              <b>Cashflow History</b>
            </Typography>
            <Typography level="body-sm">Register a consumer first.</Typography>
          </div>
          <Box>
            <Button
              sx={{ mt: 1 }}
              onClick={() => navigate('/')}
              size='sm'
            >
              Register Consumer
            </Button>
          </Box>
        </>
      )
    }
    return (
      <>
        <div>
          <Typography level="h4" component="h1">
            <b>Cashflow History</b>
          </Typography>
          <Typography level="body-sm">
            View transactions for each account.
          </Typography>
          <br />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
            }}
          >
            {state.accounts?.length
              ? <Stack direction='row' spacing={1} mb={1}>
                  <Select
                    placeholder='Account ID'
                    variant='outlined'
                    sx={{ width: '10rem' }}
                    size='sm'
                    onChange={(_, v) => setAccountId(v)}
                    value={accountId}
                  >
                    {state.accounts.filter(a => a.account_id).map(a => (
                      <Option key={a.account_id} value={a.account_id}>
                        {a.account_id}
                      </Option>
                    ))}
                  </Select>
                  <Button
                    variant='soft'
                    size='sm'
                    component='a'
                    endDecorator={<OpenInNew />}
                    onClick={getTransactions}
                    loading={loading}
                    disabled={!accountId}
                  >
                    Show Transactions
                  </Button>
                </Stack>
              : <Typography level="body-sm" mb={1}>
                  Add accounts in step 2 to show transactions. {state.accounts}
                </Typography>
            }
            <Stack direction='row' spacing={1}>
              <Button
                sx={{ mt: 1 }}
                onClick={() => navigate('/accounts')}
                size='sm'
              >
                Previous
              </Button>
              <Button
                sx={{ mt: 1 }}
                onClick={() => {
                  dispatch({ type: 'startover' })
                  navigate('/')
                }}
                size='sm'
              >
                Start Over
              </Button>
            </Stack>
          </Box>
        </div>
      </>
    )
  }

  return (
    <PageWrapper>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gridColumn: '2 / -1',
          bgcolor: 'background.surface',
          pt: 3,
          pb: 4,
          px: 4,
          animation: 'slide-up 100ms'
        }}
      >
        <Box
          sx={{
            // width: 400,
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
          }}
        >
          {renderForm()}
        </Box>
      </Box>
      {modal}
    </PageWrapper>
  )
}
