import React, { useState, useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import Sheet from '@mui/joy/Sheet'
import Typography from '@mui/joy/Typography'
import FormControl from '@mui/joy/FormControl'
import FormLabel from '@mui/joy/FormLabel'
import Input from '@mui/joy/Input'
import Button from '@mui/joy/Button'
import Link from '@mui/joy/Link'
import Box from '@mui/joy/Box'

import { AppContext } from '../App'
import api from '../util/api'
import { setJWT } from '../util/jwt'

export default function Login() {
  const navigate = useNavigate()
  const [clientId, setClientId] = useState('')
  const [apiKey, setApiKey] = useState('')
  const [loading, setLoading] = useState(false)
  const { state, dispatch, showAlert } = useContext(AppContext)

  useEffect(() => {
    if (state.webJWT) {
      navigate('/')
    }
  }, [state.webJWT, navigate])

  const handleLogin = async () => {
    if (!clientId || !apiKey) return
    const data = { email: clientId, api_key: apiKey }
    setLoading(true)
    const res = await api.login(data)
    if (!res) {
      showAlert('There was a problem with your request.')
      return
    }
    if (res.status === 200) {
      setTimeout(() => {
        dispatch({
          type: 'set',
          state: 'webJWT',
          value: res.data.token
        })
        navigate('/')
        setLoading(false)
      }, 500)
      setJWT(res.data.token)
    } else {
      showAlert(res.data.message
        ? res.data.message
        : 'There was a problem with your request.'
      )
      setLoading(false)
    }
  }

  return (
    <Box
      sx={{
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Sheet
        sx={{
          width: 300,
          mx: 'auto',
          my: 4,
          py: 3,
          px: 2,
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          borderRadius: 'sm',
          boxShadow: 'md',
        }}
        variant="outlined"
      >
        <div>
          <Typography level="h4" component="h1">
            <b>Welcome!</b>
          </Typography>
          <Typography level="body-sm">Sign in to continue.</Typography>
        </div>
        <FormControl>
          <FormLabel>Client ID</FormLabel>
          <Input
            name="clientId"
            type="password"
            placeholder="johndoe@example.com"
            value={clientId}
            onChange={e => setClientId(e.target.value)}
          />
        </FormControl>
        <FormControl>
          <FormLabel>API Key</FormLabel>
          <Input
            name="apiKey"
            type="password"
            placeholder="api-key"
            value={apiKey}
            onChange={e => setApiKey(e.target.value)}
          />
        </FormControl>
        <Button
          sx={{ mt: 1 }}
          onClick={handleLogin}
          loading={loading}
        >
          Log in
        </Button>
        <Typography
          endDecorator={<Link href="/sign-up">Sign up</Link>}
          fontSize="sm"
          sx={{ alignSelf: 'center' }}
        >
          Don't have an account?
        </Typography>
      </Sheet>
    </Box>
  )
}
