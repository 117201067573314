import React, { useContext } from 'react'

import Box from '@mui/joy/Box'
import Stack from '@mui/joy/Stack'
import Button from '@mui/joy/Button'
import Typography from '@mui/joy/Typography'

import { AppContext } from '../App'

// import IconButton from '@mui/joy/IconButton'
// import Drawer from '@mui/joy/Drawer'
// import ModalClose from '@mui/joy/ModalClose'
// import DialogTitle from '@mui/joy/DialogTitle'
// import MenuRoundedIcon from '@mui/icons-material/MenuRounded'

import pdLogo from '../assets/pentadata_logo_170.png'

export default function Header() {
  const { handleLogout } = useContext(AppContext)

  return (
    <Box
      sx={{
        display: 'flex',
        flexGrow: 1,
        justifyContent: 'space-between',
      }}
    >
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={1}
        sx={{ display: { xs: 'none', sm: 'flex' } }}
      >
        <Box ml={5} />
        <Typography
          fontSize="lg"
          fontWeight="xl"
          color="primary"
          pl={2}
        >
          Personal
        </Typography>
        <Typography
          pl={3}
          fontSize="lg"
          fontWeight="lg"
        >
          Business
        </Typography>
        <Typography
          pl={3}
          fontSize="lg"
          fontWeight="lg"
        >
          Investments
        </Typography>
      </Stack>
      {/* <Box sx={{ display: { xs: 'inline-flex', sm: 'none' } }}>
        <IconButton variant="plain" color="neutral" onClick={() => setOpen(true)}>
          <MenuRoundedIcon />
        </IconButton>
        <Drawer
          sx={{ display: { xs: 'inline-flex', sm: 'none' } }}
          open={open}
          onClose={() => setOpen(false)}
        >
          <ModalClose />
          <DialogTitle>Menu</DialogTitle>
          <Box sx={{ px: 1 }}>
          </Box>
        </Drawer>
      </Box> */}

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: 1.5,
          alignItems: 'center',
        }}
      >
        <Button variant='soft' size='sm' onClick={handleLogout}>
          Home
        </Button>
      </Box>
    </Box>
  )
}
