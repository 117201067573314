import React, { useState, useContext } from 'react'
import { useNavigate } from 'react-router-dom'

import Box from '@mui/joy/Box'
import Typography from '@mui/joy/Typography'
import Button from '@mui/joy/Button'
import Stack from '@mui/joy/Stack'
import OpenInNew from '@mui/icons-material/OpenInNew'

import PageWrapper from './page_wrapper'
import AggregatorModal from './modal/aggregator'
import InstitutionModal from './modal/institution'
import { AppContext } from '../App'

export default function Aggregate() {
  const navigate = useNavigate()
  const [modal, setModal] = useState(null)

  const { state } = useContext(AppContext)

  const closeModal = () => setModal(null)

  const selectAggregator = () => {
    setModal(<AggregatorModal onClose={closeModal} />)
  }

  const selectInstitution = () => {
    setModal(<InstitutionModal onClose={closeModal} />)
  }

  const aggTemplate = {
    'akoya': (
      <>
        <br />
        <Typography level="body-sm">
          The aggregation connector is ready!
        </Typography>
        <br />
        <Typography level="body-sm">
          On the institution page, log in with "mikomo_2023" for both usename and password.
        </Typography>
        <Typography level="body-sm">
          Navigate back to this page after you have finished.
        </Typography>
        <br />
        <Button
          variant='soft'
          size='sm'
          component='a'
          href={state.aggData?.url}
          endDecorator={<OpenInNew />}
          target='_blank'
        >
          Open Aggregation Page
        </Button>
        <br />
        <br />
      </>
    )
  }

  const renderForm = () => {
    if (!state.personId) {
      return (
        <>
          <div>
            <Typography level="h4" component="h1">
              <b>Aggregate</b>
            </Typography>
            <Typography level="body-sm">Register a consumer first.</Typography>
          </div>
          <Box>
            <Button
              sx={{ mt: 1 }}
              onClick={() => navigate('/')}
              size='sm'
            >
              Register Consumer
            </Button>
          </Box>
        </>
      )
    }
    if (state.aggData?.url) {
      return (
        <>
          <div>
            <Typography level="h4" component="h1">
              <b>Aggregate</b>
            </Typography>
            {aggTemplate[state.aggData.aggId]}
          </div>
          <Stack direction='row' spacing={1}>
            <Button
              sx={{ mt: 1 }}
              onClick={() => navigate('/')}
              size='sm'
            >
              Previous
            </Button>
            <Button
              sx={{ mt: 1 }}
              onClick={() => navigate('/accounts')}
              size='sm'
            >
              Next
            </Button>
          </Stack>
        </>
      )
    }
    return (
      <>
        <div>
          <Typography level="h4" component="h1">
            <b>Aggregate</b>
          </Typography>
          <Typography level="body-sm">Choose a mean of aggregation.</Typography>
        </div>
        <Stack direction='row' spacing={1}>
          <Button
            sx={{ mt: 1 }}
            onClick={selectAggregator}
            size='sm'
          >
            Select Aggregator
          </Button>
          <Button
            sx={{ mt: 1 }}
            onClick={selectInstitution}
            size='sm'
          >
            Select Institution
          </Button>
        </Stack>
      </>
    )
  }

  return (
    <PageWrapper>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gridColumn: '2 / -1',
          bgcolor: 'background.surface',
          pt: 3,
          pb: 4,
          px: 4,
          animation: 'slide-up 100ms'
        }}
      >
        <Box
          sx={{
            // width: 400,
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
          }}
        >
          {renderForm()}
        </Box>
      </Box>
      {modal}
    </PageWrapper>
  )
}
