import React, { useEffect, useState, useRef } from 'react'

import Box from '@mui/joy/Box'
import Typography from '@mui/joy/Typography'
import CircularProgress from '@mui/joy/CircularProgress'
import Stack from '@mui/joy/Stack'
import TaskAltIcon from '@mui/icons-material/TaskAlt'

import ConfettiExplosion from 'react-confetti-explosion'

import PageWrapper from './page_wrapper'
import creditCard from '../assets/credit_card.png'

const timeout = ms => new Promise(resolve => setTimeout(resolve, ms))

const messages = [
  'CashFlow Attributes: Affordability',
  'CashFlow Attributes: CashFlow',
  'CashFlow Attributes: Payments and Expenses',
  'CashFlow Attributes: Paycheck Income'
]

export default function AccountDetails() {
  const [loading, setLoading] = useState(true)
  const [loadedMsgs, setLoadedMsgs] = useState([])
  const msgsLoaded = useRef(false)

  useEffect(() => {
    if (msgsLoaded.current) return
    msgsLoaded.current = true
    const loadMsgs = async () => {
      for (const msg of messages) {
        await timeout(1000)
        setLoadedMsgs((prevMsgs) => [...prevMsgs, msg])
      }
      await timeout(1000)
      setLoading(false)
    }
    loadMsgs()
  }, [])

  const render = () => {
    if (loading) {
      return (
        <Stack
          direction='column'
          alignItems='center'
          gap={4}
          sx={{ animation: 'slide-up 300ms' }}
        >
          <Box gap={4} sx={{ mt: 8 }}>
            <Typography fontSize={32} color='primary' fontWeight={500}>
              Gathering account details…
            </Typography>
            <Box
              height={120}
              alignItems='center'
              justifyContent='center'
              display='flex'
              flexDirection='column'
            >
              <CircularProgress size='md' thickness={2} />
            </Box>
          </Box>
          <Stack
            gap={1}
            sx={{
              minWidth: 420
            }}
          >
            {loadedMsgs.map((msg, i) => (
              <Box
                key={`msg-${i}`}
                display='flex'
                flexDirection='row'
                alignItems='center'
                gap={1}
              >
                <TaskAltIcon color='success' />
                <Typography fontSize={18} color='neutral'>
                  {msg}
                </Typography>
              </Box>
            ))}
          </Stack>
        </Stack>
      )
    }
    return (
      <Stack
        direction='column'
        alignItems='center'
        display='flex'
        justifyContent='center'
        height='100%'
        sx={{ animation: 'slide-up 300ms' }}
      >
        <ConfettiExplosion />
        <Box
          alignItems='center'
          justifyContent='center'
          display='flex'
          flexDirection='column'
          gap={2}
        >
          <Typography fontSize={42} color='primary' fontWeight={500} textAlign='center'>
            Congratulations!
          </Typography>
          <Typography fontSize={22} color='primary' fontWeight={500} textAlign='center'>
            Application successful.
            <br />
            Your new card is on your way to your address!
          </Typography>
          <img src={creditCard} alt='Credit Card' style={{ width: '250px', margin: '2rem 0' }} />
        </Box>
      </Stack>
    )
  }

  return (
    <PageWrapper>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          p: 2,
        }}
      >
        {render()}
      </Box>
    </PageWrapper>
  )
}
