import React, { useState, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import urljoin from 'url-join'

import Box from '@mui/joy/Box'
import Typography from '@mui/joy/Typography'
import Button from '@mui/joy/Button'
import Modal from '@mui/joy/Modal'
import Input from '@mui/joy/Input'
import ModalDialog from '@mui/joy/ModalDialog'
import ModalClose from '@mui/joy/ModalClose'
import Stack from '@mui/joy/Stack'
import CircularProgress from '@mui/joy/CircularProgress'
import SearchIcon from '@mui/icons-material/Search'
import OpenInNew from '@mui/icons-material/OpenInNew'

import chaseLogo from '../../assets/chase.svg'
import wfLogo from '../../assets/wellsfargo.png'
import citiLogo from '../../assets/citi.svg'
import boaLogo from '../../assets/boa.svg'
import pncLogo from '../../assets/pnc.png'
import expLogo from '../../assets/experian.png'

import { AppContext } from '../../App'
import api from '../../util/api'
import { FRONTEND_URL } from '../../util/config'

const timeout = ms => new Promise(resolve => setTimeout(resolve, ms))

export default function Institution({ onClose }) {
  const [loading, setLoading] = useState('')
  const [urlOpened, setUrlOpened] = useState(false)
  const { state, dispatch, showAlert } = useContext(AppContext)
  const navigate = useNavigate()

  const closeModal = () => {
    dispatch({ type: 'set', state: 'bankData', value: null })
    onClose()
  }

  const handleSubmit = async (bankId) => {
    const demoBankId = 'akoya'
    const data = {
      person_id: state.personId,
      redirect_uri: urljoin(FRONTEND_URL, '/aggregate'),
      institution_id: 'mikomo'
    }
    setLoading(bankId)
    await timeout(1000)
    const res = await api.aggregate(state.webJWT, data, demoBankId)
    setLoading(false)
    if (!res || res.status !== 200) {
      showAlert('There was a problem with your request.')
      return
    }
    // NOTE !!!
    // For the sake of the demo
    // we change and hard-code the Demo Bank URL.
    const tmp = 'https://test-app.pentadatainc.com/#/sandbox-bank-1/n4vwi57niw745v4i754'
    //dispatch({ type: 'set', state: 'bankData', value: { url: res.data, demoBankId } })
    dispatch({ type: 'set', state: 'bankData', value: { url: tmp, demoBankId } })

  }

  const banks = [
    {
      id: 'chase',
      name: 'Chase',
      logo: chaseLogo,
      onClick: () => { }
    },
    {
      id: 'wellsfargo',
      name: 'Wells Fargo',
      logo: wfLogo,
      onClick: () => { }
    },
    {
      id: 'pncbank',
      name: 'PNC Bank',
      logo: pncLogo,
      onClick: () => { }
    },
    {
      id: 'bankofamerica',
      name: 'Bank Of America',
      logo: boaLogo,
      onClick: () => { }
    },
    {
      id: 'citibank',
      name: 'Citi Bank',
      logo: citiLogo,
      onClick: () => { }
    },
    {
      id: 'demobank',
      name: 'Demo Bank',
      text: (
        <Typography
          color='primary'
          sx={{
            fontFamily: 'Orbitron',
            fontWeight: 700,
            fontSize: '1.3em',
            width: '140px',
            textAlign: 'center'
          }}
          onClick={() => handleSubmit('demobank')}
        >
          Demo Bank
        </Typography>
      ),
      onClick: () => { }
    },
  ]

  const renderLogo = (bank) => {
    if (loading === bank.id) {
      return (
        <Box sx={{ width: '140px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <CircularProgress size='sm' variant='soft' />
        </Box>
      )
    }
    if (bank.text) {
      return bank.text
    }
    return (
      <img
        src={bank.logo}
        alt={bank.name}
        style={{ width: '140px' }}
        onClick={bank.onClick}
      />
    )
  }

  const render = () => {
    if (urlOpened) {
      return (
        <Stack
          spacing={2}
          flexWrap='wrap'
          useFlexGap
          sx={{ width: '360px', my: 1, animation: 'slide-up 200ms' }}
        >
          <Button
            size='sm'
            onClick={() => {
              closeModal()
              navigate('/account-details')
            }}
            sx={{ mb: 6 }}
          >
            Bank connected. Continue my application.
          </Button>
        </Stack>
      )
    }
    if (state.bankData?.url) {
      return (
        <Stack
          spacing={2}
          flexWrap='wrap'
          useFlexGap
          sx={{ width: '360px', my: 1, animation: 'slide-up 400ms' }}
        >
          <Button
            size='sm'
            component='a'
            href={state.bankData?.url}
            endDecorator={<OpenInNew />}
            target='_blank'
            onClick={() => {
              setUrlOpened(true)
            }}
          >
            Open Bank Connector
          </Button>
          <Typography
            color='neutral'
            sx={{
              fontWeight: 500,
              mb: 6,
              mt: 6,
              mx: 2,
              textAlign: 'center',
            }}
          >
            Return to this page after you have completed the connection.
          </Typography>
        </Stack>
      )
    }
    return (
      <>
        <Input
          endDecorator={<SearchIcon />}
          placeholder='Search for an institution...'
        />
        <Stack
          spacing={2}
          direction='row'
          flexWrap='wrap'
          useFlexGap
          sx={{ width: '360px', my: 1 }}
        >
          {banks.map((bank, i) => (
            <Box
              key={i}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                p: 2,
                boxShadow: '0 0 8px rgba(0,0,0,0.1)',
                borderRadius: 'md',
                cursor: 'pointer',
                '&:hover': { boxShadow: '0 0 8px rgba(0,0,0,0.2)' },
                transition: 'box-shadow 0.2s',
                maxHeight: '90px'
              }}
            >
              {renderLogo(bank)}
            </Box>
          ))}
        </Stack>
      </>
    )
  }

  return (
    <Modal open={true} onClose={onClose} sx={{ animation: 'slide-up 100ms' }}>
      <ModalDialog>
        <ModalClose />
        <br />
        {render()}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 2,
          }}
        >
          <Box sx={{ position: 'relative', mt: 3.5 }}>
            <Typography
              color='neutral'
              sx={{
                fontFamily: 'Varela Round',
                fontWeight: 600,
                fontSize: '.6em',
                position: 'absolute',
                right: 12,
                top: -15
              }}
            >
              powered by
            </Typography>
            <img
              src={expLogo}
              alt='Experian'
              style={{ width: '80px' }}
            />
          </Box>
        </Box>
      </ModalDialog>
    </Modal>
  )
}
