import React, { useState, useContext } from 'react'

import Box from '@mui/joy/Box'
import Typography from '@mui/joy/Typography'
import Button from '@mui/joy/Button'
import Modal from '@mui/joy/Modal'
import Input from '@mui/joy/Input'
import ModalDialog from '@mui/joy/ModalDialog'
import ModalClose from '@mui/joy/ModalClose'
import Stack from '@mui/joy/Stack'
import CircularProgress from '@mui/joy/CircularProgress'

import InstitutionModal from './institution'
import { AppContext } from '../../App'
import api from '../../util/api'

const timeout = ms => new Promise(resolve => setTimeout(resolve, ms))

export default function Person({ onClose }) {
  const [email, setEmail] = useState('')
  const [connectBanks, setConnectBanks] = useState(false)
  const [loading, setLoading] = useState(false)
  const { state, dispatch, showAlert } = useContext(AppContext)

  const closeModal = () => {
    onClose()
    setConnectBanks(false)
  }

  const setModal = (modal) => {
    dispatch({ type: 'set', state: 'modal', value: modal })
  }

  const handleContinue = () => {
    closeModal()
    dispatch({
      type: 'set',
      state: 'modal',
      value: (
        <InstitutionModal onClose={() => setModal(null)} />
      )
    })
  }

  const handleSubmit = async () => {
    setLoading(true)
    const data = {
      first_name: '',
      last_name: '',
      email
    }
    try {
      await timeout(2000)
      const resp = await api.register(state.webJWT, data)
      dispatch({
        type: 'set',
        state: 'personId',
        value: resp.data.person_id
      })
      setConnectBanks(true)
    }
    catch (e) {
      console.log('Error registering consumer', e)
      showAlert('There was a problem with your request.')
    }
    finally {
      setLoading(false)
    }
  }

  const render = () => {
    if (connectBanks) {
      return (
        <Stack
          direction='column'
          alignItems='center'
          gap={4}
          sx={{ animation: 'slide-up 300ms' }}
        >
          <Typography fontSize={22} color='neutral' textAlign='center'>
            To continue your application, please connect your main bank accounts
          </Typography>
          <Button onClick={handleContinue}>
            Continue
          </Button>
        </Stack>
      )
    }
    if (loading) {
      return (
        <Stack
          direction='column'
          alignItems='center'
          gap={4}
          sx={{ animation: 'slide-up 300ms' }}
        >
          <Typography fontSize={22} color='neutral'>
            Checking your eligibility…
          </Typography>
          <CircularProgress size='md' thickness={2} />
        </Stack>
      )
    }
    return (
      <>
        <Typography
          color='primary'
          fontWeight={500}
          fontSize={20}
          textAlign='center'
          sx={{ width: 500 }}
        >
          Please provide your personal information
          <br />
          to confirm your eligibility
        </Typography>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            gap: 1,
            width: 330,
            m: 2
          }}
        >
          <Input
            name='fullName'
            type='text'
            placeholder='Full Name'
            size='sm'
          />
          <Input
            name='address'
            type='text'
            placeholder='Address'
            size='sm'
          />
          <Box sx={{ display: 'flex', gap: 1 }}>
            <Input
              name='city'
              type='text'
              placeholder='City'
              size='sm'
            />
            <Input
              name='postal'
              type='text'
              placeholder='Postal Code'
              size='sm'
            />
          </Box>
          <Input
            name='email'
            type='text'
            placeholder='Email'
            size='sm'
            value={email}
            onChange={e => setEmail(e.target.value)}
          />
        </Box>
        <Button size='sm' onClick={handleSubmit}>
          Submit
        </Button>
      </>
    )
  }

  return (
    <Modal open={true} onClose={closeModal} sx={{ animation: 'slide-up 100ms' }}>
      <ModalDialog>
        <ModalClose />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 1,
            width: 500,
            minHeight: 200,
            p: 3
          }}
        >
          {render()}
        </Box>
      </ModalDialog>
    </Modal>
  )
}
