import * as React from 'react'
import Box from '@mui/joy/Box'
import Header from './header'
// import Nav from './nav'

const style = {
  container: {
    bgcolor: 'background.appBody',
    display: 'grid',
    // gridTemplateColumns: {
    //   xs: '1fr',
    //   sm: 'minmax(64px, 200px) minmax(450px, 1fr)',
    //   md: 'minmax(160px, 300px) minmax(300px, 500px) minmax(500px, 1fr)',
    // },
    gridTemplateRows: '64px 1fr',
    minHeight: '100vh',
  },
  header: {
    p: 2,
    gap: 2,
    bgcolor: 'background.surface',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    gridColumn: '1 / -1',
    borderBottom: '1px solid',
    borderColor: 'divider',
    position: 'sticky',
    top: 0,
    zIndex: 1100,
    height: '75px'
  },
  nav: {
    p: 2,
    bgcolor: 'background.surface',
    borderRight: '1px solid',
    borderColor: 'divider',
    display: {
      xs: 'none',
      sm: 'initial',
    }
  }
}

export default function PageWrapper({ children }) {
  return (
    <Box sx={style.container}>
      <Box sx={style.header}>
        <Header />
      </Box>
      {/* <Box sx={style.nav}>
        <Nav />
      </Box> */}
      {children}
    </Box>
  )
}
