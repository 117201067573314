import urljoin from 'url-join'
import { API_URL } from './config'
import axios from 'axios'

const getHeaders = (token) => {
  const headers = {
    'content-type': 'application/json',
    'Authorization': `Bearer ${token}`
  }
  return headers
}

const api = {
  login: async (data) => {
    const config = {
      headers: {
        'content-type': 'application/json'
      }
    }
    try {
      const res = await axios.post(urljoin(API_URL, '/subscribers/login'), data, config)
      return {
        status: res.status,
        data: res.data
      }
    }
    catch (e) {
      console.log('Error logging in', e)
      return null
    }
  },

  register: async (jwt, data) => {
    const config = {
      headers: getHeaders(jwt)
    }
    try {
      const res = await axios.post(urljoin(API_URL, '/persons'), data, config)
      return {
        status: res.status,
        data: res.data
      }
    }
    catch (e) {
      console.log('Error token request', e)
      return null
    }
  },

  aggregate: async (jwt, data, aggId) => {
    const config = {
      headers: getHeaders(jwt)
    }
    try {
      const res = await axios.post(urljoin(API_URL, `/accounts-direct/${aggId}`), data, config)
      return {
        status: res.status,
        data: res.data
      }
    }
    catch (e) {
      console.log('Error token request', e)
      return null
    }
  },

  accounts: async (jwt, pId) => {
    const config = {
      headers: getHeaders(jwt)
    }
    try {
      const res = await axios.get(urljoin(API_URL, `/persons/${pId}/accounts`), config)
      return {
        status: res.status,
        data: res.data
      }
    }
    catch (e) {
      console.log('Error token request', e)
      return null
    }
  },

  transactions: async (jwt, aggId, params) => {
    const config = {
      headers: getHeaders(jwt),
      params
    }
    try {
      const res = await axios.get(urljoin(API_URL, `/transactions-direct/${aggId}`), config)
      return {
        status: res.status,
        data: res.data
      }
    }
    catch (e) {
      console.log('Error token request', e)
      return null
    }
  },

}

export default api
