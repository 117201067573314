export const getJWT = () => localStorage.getItem('jwt')

export const setJWT = (token) => localStorage.setItem('jwt', token)

export const removeJWT = () => localStorage.removeItem('jwt')

export const decodeJWT = (token) => JSON.parse(atob(token.split('.')[1]))

export const verifyJWT = async (token) => {
  const nowInSeconds = Date.now() / 1000;
  const payload = decodeJWT(token)

  // Add 30 seconds to the current time
  if (payload.exp >= nowInSeconds + 30) {
    return true
  }
  removeJWT()
  return false
}
