import React, { useState, useContext } from 'react'
import urljoin from 'url-join'

import Box from '@mui/joy/Box'
import Input from '@mui/joy/Input'
import Modal from '@mui/joy/Modal'
import ModalDialog from '@mui/joy/ModalDialog'
import ModalClose from '@mui/joy/ModalClose'
import Stack from '@mui/joy/Stack'
import CircularProgress from '@mui/joy/CircularProgress'
import SearchIcon from '@mui/icons-material/Search'

import akoyaLogo from '../../assets/akoya.svg'
import finLogo from '../../assets/finicity.png'
import mxLogo from '../../assets/mx.svg'
import plaidLogo from '../../assets/plaid.png'
import yodleeLogo from '../../assets/yodlee.svg'
import tinkLogo from '../../assets/tink.png'

import { AppContext } from '../../App'
import api from '../../util/api'
import { REDIRECT_URL } from '../../util/config'

export default function Aggregator({ onClose }) {
  const [loading, setLoading] = useState('')
  const { state, dispatch, showAlert } = useContext(AppContext)

  const handleSubmit = async (aggId) => {
    const data = {
      person_id: state.personId,
      redirect_uri: urljoin(REDIRECT_URL, '/accounts', `?state=${state.personId}-${aggId}`),
      institution_id: 'mikomo'
    }
    setLoading(aggId)
    const res = await api.aggregate(state.webJWT, data, aggId)
    setLoading(false)
    if (!res || res.status !== 200) {
      showAlert('There was a problem with your request.')
      return
    }
    // Note !!!
    // For the sake of the demo
    // we change and hard-code the Demo Bank URL.
    const tmp = 'https://test-app.pentadatainc.com/#/sandbox-bank-1/n4vwi57niw745v4i754'
    //dispatch({ type: 'set', state: 'aggData', value: { url: res.data, aggId } })
    dispatch({ type: 'set', state: 'aggData', value: { url: tmp, aggId } })
    onClose()
  }

  const aggs = [
    {
      id: 'akoya',
      name: 'Akoya',
      logo: akoyaLogo,
      onClick: () => handleSubmit('akoya')
    },
    {
      id: 'finicity',
      name: 'Finicity',
      logo: finLogo,
      onClick: () => { }
    },
    {
      id: 'mx',
      name: 'MX',
      logo: mxLogo,
      onClick: () => { }
    },
    {
      id: 'plaid',
      name: 'Plaid',
      logo: plaidLogo,
      onClick: () => { }
    },
    {
      id: 'tink',
      name: 'Tink',
      logo: tinkLogo,
      onClick: () => { }
    },
    {
      id: 'yodlee',
      name: 'Yodlee',
      logo: yodleeLogo,
      onClick: () => { }
    },
  ]

  return (
    <Modal open={true} onClose={onClose} sx={{ animation: 'slide-up 100ms' }}>
      <ModalDialog>
        <ModalClose />
        <br />
        <Input
          endDecorator={<SearchIcon />}
          placeholder='Search for an aggregator...'
        />
        <Stack
          spacing={2}
          direction='row'
          flexWrap='wrap'
          useFlexGap
          sx={{ width: '360px' }}
        >
          {aggs.map((agg, i) => (
            <Box
              key={i}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                p: 2,
                boxShadow: '0 0 8px rgba(0,0,0,0.1)',
                borderRadius: 'md',
                cursor: 'pointer',
                '&:hover': { boxShadow: '0 0 8px rgba(0,0,0,0.2)' },
                transition: 'box-shadow 0.2s',
                maxHeight: '90px'
              }}
            >
              {loading === agg.id
                ? <Box sx={{ width: '140px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <CircularProgress size='sm' variant='soft' />
                  </Box>
                : <img
                    src={agg.logo}
                    alt={agg.name}
                    style={{ width: '140px' }}
                    onClick={agg.onClick}
                  />
              }
            </Box>
          ))}
        </Stack>
      </ModalDialog>
    </Modal>
  )
}
