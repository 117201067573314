import React, { useContext } from 'react'

import Box from '@mui/joy/Box'
import Typography from '@mui/joy/Typography'
import Button from '@mui/joy/Button'
import ArrowForward from '@mui/icons-material/ArrowForward'

import Person from './modal/person'
import PageWrapper from './page_wrapper'
import TwoSidedLayout from './two_sided_layout'
import { AppContext } from '../App'

import block1Img from '../assets/block1.jpg'
import block2Img from '../assets/block2.jpg'

const Block1 = ({ submit }) => {
  return (
    <TwoSidedLayout imgSrc={block1Img}>
      <Typography color="primary" fontSize="lg" fontWeight="lg">
        The power to do more
      </Typography>
      <Typography
        level="h1"
        fontWeight="xl"
        fontSize="clamp(1.875rem, 1.3636rem + 2.1818vw, 3rem)"
      >
        Unlimited Platfond
        <br />
        Unlmited Cashback
        <br />
        Simply Unlimited
      </Typography>
      <Typography fontSize="lg" textColor="text.secondary" lineHeight="lg">
        Apply now for our credit card and receive it at your address in five days.
      </Typography>
      <Button 
        size="lg"
        endDecorator={<ArrowForward fontSize="xl" />}
        onClick={submit}
      >
        Apply
      </Button>
    </TwoSidedLayout>
  )
}

const Block2 = ({ submit }) => {
  return (
    <TwoSidedLayout imgSrc={block2Img} reversed>
      <Typography color="primary" fontSize="lg" fontWeight="lg">
        The power to do more
      </Typography>
      <Typography
        level="h1"
        fontWeight="xl"
        fontSize="clamp(1.875rem, 1.3636rem + 2.1818vw, 3rem)"
      >
        Unlimited Platfond
        <br />
        Unlmited Cashback
        <br />
        Simply Unlimited
      </Typography>
      <Typography fontSize="lg" textColor="text.secondary" lineHeight="lg">
        Apply now for our credit card and receive it at your address in five days.
      </Typography>
      <Button
        size="lg"
        endDecorator={<ArrowForward fontSize="xl" />}
        onClick={submit}
      >
        Apply
      </Button>
    </TwoSidedLayout>
  )
}

const Home = () => {
  const { state, dispatch } = useContext(AppContext)

  const modal = state.modal
  const setModal = (component) =>
    dispatch({ type: 'set', state: 'modal', value: component })

  const submitApply = () => {
    setModal(<Person onClose={() => setModal(null)} />)
  }

  return (
    <PageWrapper>
      <Box
        sx={{
          minHeight: 'calc(100vh - 75px)',
          overflowY: 'scroll',
          scrollSnapType: 'y mandatory',
          '& > div': {
            scrollSnapAlign: 'start',
          },
        }}
      >
        <Block1 submit={submitApply} />
        <Block2 submit={submitApply} />
      </Box>
      {modal}
    </PageWrapper>
  )
}

export default Home
