import React from 'react'

import Box from '@mui/joy/Box'
import Typography from '@mui/joy/Typography'
import Modal from '@mui/joy/Modal'
import ModalDialog from '@mui/joy/ModalDialog'
import ModalClose from '@mui/joy/ModalClose'

export default function JsonModal({ data, handleClose, title }) {
  return (
    <Modal open={true} onClose={handleClose}>
      <ModalDialog sx={{ minHeight: '20rem', minWidth: '40rem' }}>
        <ModalClose />
        <Typography>
          <b>{title}</b>
        </Typography>
        <Box
          sx={{
            bgcolor: 'background.level1',
            p: 2,
            fontFamily: 'monospace',
            maxHeight: '60vh',
            maxWidth: '60vw',
            overflowY: 'scroll',
            '& pre': {
              m: 0,
              fontSize: '.8em',
            }
          }}
        >
          <pre>{JSON.stringify(data, null, 2)}</pre>
        </Box>
      </ModalDialog>
    </Modal>
  )
}
