import React, { useState, useContext, useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

import Box from '@mui/joy/Box'
import Typography from '@mui/joy/Typography'
import Button from '@mui/joy/Button'
import Stack from '@mui/joy/Stack'
import OpenInNew from '@mui/icons-material/OpenInNew'

import PageWrapper from './page_wrapper'
import JsonModal from './modal/json'
import { AppContext } from '../App'
import api from '../util/api'

export default function Accounts() {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [modal, setModal] = useState(null)
  const { state, dispatch, showAlert } = useContext(AppContext)
  const [searchParams] = useSearchParams()

  useEffect(() => {
    const urlState = searchParams.get('state')
    if (!urlState) return
    const urlStateParts = urlState.split('-')
    const pId = urlStateParts[0]
    const aggId = urlStateParts[1]
    if (pId) {
      dispatch({ type: 'set', state: 'personId', value: Number(pId) })
    }
    if (aggId) {
      dispatch({ type: 'set', state: 'aggData', value: { aggId } })
    }
    // eslint-disable-next-line
  }, [])

  const getAccounts = async () => {
    setLoading(true)
    const res = await api.accounts(state.webJWT, state.personId)
    setLoading(false)
    if (!res || res.status !== 200) {
      showAlert('There was a problem with your request.')
      return
    }
    dispatch({
      type: 'set',
      state: 'accounts',
      value: res.data.accounts
    })
    setModal(
      <JsonModal
        data={res.data}
        title='Accounts'
        handleClose={()=> setModal(null)}
      />
    )
  }

  const renderForm = () => {
    if (!state.personId) {
      return (
        <>
          <div>
            <Typography level="h4" component="h1">
              <b>Accounts</b>
            </Typography>
            <Typography level="body-sm">Register a consumer first.</Typography>
          </div>
          <Box>
            <Button
              sx={{ mt: 1 }}
              onClick={() => navigate('/')}
              size='sm'
            >
              Register Consumer
            </Button>
          </Box>
        </>
      )
    }
    return (
      <>
        <div>
          <Typography level="h4" component="h1">
            <b>Accounts</b>
          </Typography>
          <Typography level="body-sm">
            View details of linked accounts.
          </Typography>
          <br />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
            }}
          >
            <Box mb={1}>
              <Button
                variant='soft'
                size='sm'
                component='a'
                endDecorator={<OpenInNew />}
                onClick={getAccounts}
                loading={loading}
              >
                Show Accounts
              </Button>
            </Box>
            <Stack direction='row' spacing={1}>
              <Button
                sx={{ mt: 1 }}
                onClick={() => navigate('/aggregate')}
                size='sm'
              >
                Previous
              </Button>
              <Button
                sx={{ mt: 1 }}
                onClick={() => navigate('/cashflow')}
                size='sm'
              >
                Next
              </Button>
            </Stack>
          </Box>
        </div>
      </>
    )
  }

  return (
    <PageWrapper>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gridColumn: '2 / -1',
          bgcolor: 'background.surface',
          pt: 3,
          pb: 4,
          px: 4,
          animation: 'slide-up 100ms'
        }}
      >
        <Box
          sx={{
            // width: 400,
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
          }}
        >
          {renderForm()}
        </Box>
      </Box>
      {modal}
    </PageWrapper>
  )
}
