import React, { useContext } from 'react'

import Box from '@mui/joy/Box'
import Typography from '@mui/joy/Typography'
import Modal from '@mui/joy/Modal'
import ModalDialog from '@mui/joy/ModalDialog'
import Button from '@mui/joy/Button'

import { AppContext } from '../../App'

export default function Message({ content, disableOk }) {
  const { dispatch } = useContext(AppContext)
  const handleClose = () => {
    dispatch({ type: 'set', state: 'msgModal', value: null })
  }
  if (!content) return null
  return (
    <Modal open={true} onClose={handleClose}>
      <ModalDialog>
        <Typography>
          {content}
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          {!disableOk &&
            <Button
              size='small'
              variant='plain'
              onClick={handleClose}
            >
              Close
            </Button>}
        </Box>
      </ModalDialog>
    </Modal>
  )
}
